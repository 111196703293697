<template>
  <v-container class="tw-py-2 md:tw-py-5">
    <div>
      <div class="tw-px-0 md:tw-px-6 tw-mt-2">
        <div class="tw-px-0 md:tw-px-6">
          <h2 v-if="isGarage" class="tw-text-xl c-blue-text tw-font-bold">
            Garage: {{ scanObject(booking, 'garage.name') }}
          </h2>
          <p v-else>
            Compare the Assessment report to the bid reports to determine which
            bid is the most responsive and should be authorized for repairs. The
            authorize button is located at the bottom of the page, within a
            series of garages labeled with numbers.
          </p>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Client Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Assessment No.</span>
                <span class="c-gray-text">{{ scanObject(booking, 'id') }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Claim No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.claimNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Insured Name</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'clientInfo.name')
                }}</span>
              </div>
            </div>
            <div>
              <div
                v-if="scanObject(booking, 'createdAt')"
                class="tw-grid tw-grid-cols-2 tw-flex tw-items-center"
              >
                <span class="c-gray-text tw-font-bold">Date Created</span>
                <span class="c-gray-text">{{
                  booking.createdAt | formatToHuman
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Policy No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'policyInfo.policyNumber')
                }}</span>
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Vehicle Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Registration No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'vehicleInfo.registrationNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Vehicle Color</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.color')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Engine No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.engineNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Make</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.make')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">YOM</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.yearOfManufacture')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Chassis No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.chassisNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Model</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.model')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Vehicle Type</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.bodyType')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Mileage</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.mileage')
                }}</span>
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Assessment Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <span class="c-gray-text tw-font-bold"
                  >Assessment Company
                </span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'externalAssessor.name')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <span class="c-gray-text tw-font-bold">PAV</span>
                <span class="c-gray-text">
                  KES
                  {{
                    scanObject(booking, 'biddingForm.preAccidentValue', 0)
                      | formatCurrency
                  }}</span
                >
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center py-1">
                <span class="c-gray-text tw-font-bold">Salvage Value</span>
                <span class="c-gray-text">
                  KES
                  {{
                    scanObject(booking, 'biddingForm.salvageValue', 0)
                      | formatCurrency
                  }}</span
                >
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Garage Bid Report
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <p>
            Submit your Bid on the Vehicle for confirmation of the estimated
            prices of vehicle parts before repairs and quantity required, grade
            and quality of the parts as well as their prices.
          </p>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">Parts/Services</th>
                    <th scope="col" class="text-left">Type</th>
                    <th scope="col" class="text-left">Quantity</th>
                    <th scope="col" class="text-left">Unit Price</th>
                    <th scope="col" class="text-left">Total</th>
                    <th scope="col" class="text-left">VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parts" :key="item.name">
                    <td>{{ item.part }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.unitPrice | formatCurrency }}</td>
                    <td>{{ getTotal(item) | formatCurrency }}</td>
                    <td>
                      <v-switch v-model="item.vat"></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr />
            <div
              class="tw-flex tw-justify-start md:tw-justify-end tw-py-2 md:tw-py-4"
            >
              <div class="tw-w-full md:tw-w-1/3">
                <div class="tw-grid tw-grid-cols-2 c-gray-text">
                  <h6>Net Total</h6>
                  <h6>Ksh. {{ netTotal | formatCurrency }}</h6>
                </div>
                <div class="tw-grid tw-grid-cols-2 c-gray-text">
                  <h6>VAT 16%</h6>
                  <h6>Ksh. {{ vatTotal | formatCurrency }}</h6>
                </div>
                <div
                  v-if="contributionTotal > 0"
                  class="tw-grid tw-grid-cols-2 c-gray-text"
                >
                  <h6>Client contribution</h6>
                  <h6>(Ksh. {{ contributionTotal | formatCurrency }})</h6>
                </div>
                <div
                  class="tw-grid tw-grid-cols-2 c-gray-text tw-text-xl md:tw-text-2xl"
                >
                  <h6>TOTAL</h6>
                  <h6>Ksh. {{ grandTotal | formatCurrency }}</h6>
                </div>
              </div>
            </div>
            <hr />
            <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
              Additional Information
            </h2>
            <hr class="tw-mb-2 md:tw-mb-5" />
            <p
              v-html="scanObject(booking, 'biddingForm.additionalInformation')"
            ></p>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Damaged Vehicle Photos
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <p>
            Attach and submit the photos of the damaged vehicle to show where
            necessarily repairs should be done.
          </p>

          <images :photos="photos" />

          <p class="tw-mt-2 md:tw-mt-6">
            As an assessor do you agree to the contents of this document as
            captured by you and the help your team? If you do check below.
          </p>
          <div
            class="
              tw-grid tw-grid-cols-1
              md:tw-grid-cols-2
              tw-gap-2
              md:tw-gap-10
            "
          >
            <div>
<div v-if="scanObject(booking, 'biddingForm.confidentialInformationFromExternalAssessor')">
  <hr />
  <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
    Assessor's Comment
  </h2>
  <hr class="tw-mb-2 md:tw-mb-5" />
  <p
      v-html="scanObject(booking, 'biddingForm.confidentialInformationFromExternalAssessor')"
  ></p>
</div>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <span>Assessor's Signature</span>
              <v-text-field
                placeholder="Full Name"
                :rules="[required('Full Name')]"
                disabled
                :value="scanObject(booking, 'biddingForm.assessorName')"
              ></v-text-field>
            </div>
            <div v-if="isGarage" class="tw-mt-2 md:tw-mt-0">
              <span class="c-blue-text">Repairer's Signature</span>
              <v-text-field
                placeholder="Full Name"
                :rules="[required('Full Name')]"
                disabled
                :value="scanObject(booking, 'garage.contact_person')"
              ></v-text-field>
            </div>
            <div v-if="isGarage" class="tw-mt-2 md:tw-mt-0">
              <p>
                As the claims committee do you agree to the contents of this
                document as Captured by the service provider? If you do check
                below to authorize repairs.
              </p>
              <v-checkbox label="Authorize Repair"></v-checkbox>
            </div>
          </div>
        </div>
        <div v-if="isGarage" class="tw-flex tw-justify-center">
          <div
            class="
              tw-w-2/3
              md:tw-w-1/3
              tw-py-4
              md:tw-py-10
              tw-space-x-2 tw-flex tw-justify-center
            "
          >
            <v-btn color="warning" :loading="submitting" :disabled="!isValid"
              >Finish</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { formatCurrency } from '@/utils/helpers'
import { VAT } from '@/utils/const'
import Validations from '@/utils/Validations'

import { formatToHuman } from '@/utils/time'
import Images from '@/components/Images'
import BiddingFormMixin from "@/mixins/biddingFormMixin";

export default {
  name: 'garageBidTemplate',
  components: { Images },
  props: {
    booking: {
      required: true,
      type: Object,
    },
    isGarage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vatRate: VAT,
      ...Validations,
      submitting: false,
      isValid: false,
    }
  },
  computed: {
    parts() {
      return this.scanObject(this.booking, 'biddingForm.repairs', [])
    },
    photos() {
      return this.scanObject(this.booking, 'biddingForm.photos', [])
    },
  },
  mixins: [BiddingFormMixin],
  filters: {
    formatCurrency,
    formatToHuman,
  },
}
</script>
