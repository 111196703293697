<template>
  <div>
    <h2 class="tw-text-xl tw-mt-2 c-gray-text">Client Details</h2>
    <hr />
    <v-form v-model="isValid" ref="form">
      <div
        class="
          tw-grid tw-grid-cols-1
          md:tw-grid-cols-2
          tw-gap-0
          md:tw-gap-8
          tw-py-4
          md:tw-py-6
        "
      >
        <div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <span class="c-gray-text tw-font-bold">Assessment No.</span>
            <span class="c-gray-text">{{ booking.id }}</span>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <label  for="claim-no" class="c-gray-text tw-font-bold">Claim Number</label>
            <v-text-field
              v-model="form.claimNumber"
              value="10/700/001245"
              id="claim-no"
              :rules="[required('Claim Number')]"
            ></v-text-field>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <span class="c-gray-text tw-font-bold">Insured’s Name </span>
            <span class="c-gray-text">{{ booking.clientInfo.name }}</span>
          </div>
        </div>
        <div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <span class="c-gray-text tw-font-bold">Date Created</span>
            <span class="c-gray-text">{{
              booking.createdAt | formatToHuman
            }}</span>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw-mt-3
            "
          >
            <span class="c-gray-text tw-font-bold">Policy Number</span>
            <span class="c-gray-text">{{
              booking.policyInfo.policyNumber
            }}</span>
          </div>
        </div>
      </div>
      <h2 class="tw-text-xl tw-mt-2 c-gray-text">Vehicle Details</h2>
      <hr />
      <div
        class="
          tw-grid tw-grid-cols-1
          md:tw-grid-cols-3
          tw-gap-0
          md:tw-gap-8
          tw-py-4
          md:tw-py-6
        "
      >
        <div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <span class="c-gray-text tw-font-bold">Registration No.</span>
            <span class="c-gray-text">{{
              booking.vehicleInfo.registrationNumber
            }}</span>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <label  for="v-color" class="c-gray-text tw-font-bold">Vehicle Color</label>
            <v-text-field
              v-model="form.color"
              value="G50"
              id="v-color"
              :rules="[required('Vehicle Color')]"
            ></v-text-field>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="engine-no" class="c-gray-text tw-font-bold">Engine Number</label>
            <v-text-field
              v-model="form.engineNumber"
              value="000026"
              id="engine-no"
              :rules="[required('Engine Number')]"
            ></v-text-field>
          </div>
        </div>
        <div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="make" class="c-gray-text tw-font-bold">Make</label>
            <v-autocomplete
              id="make"
              v-model="form.make"
              type="text"
              :items="vehicleMakes"
              item-text="makeName"
              class="tw-mb-5"
              hide-no-data
              hide-details="auto"
              placeholder="e.g TOYOTA"
              @change="handleMakeChange"
              :rules="[required('Make')]"
            ></v-autocomplete>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="year" class="c-gray-text tw-font-bold">YOM</label>
            <v-autocomplete
              id="year"
              v-model="form.yearOfManufacture"
              type="number"
              :items="yearList"
              :search-input.sync="yearSearch"
              cache-items
              class="tw-mb-5"
              hide-no-data
              hide-details
              placeholder="e.g 2011"
              :rules="[required('YOM')]"
            ></v-autocomplete>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="chassis-no" class="c-gray-text tw-font-bold">Chassis No.</label>
            <v-text-field
              v-model="form.chassisNumber"
              placeholder="e.g 202983"
              id="chassis-no"
              :rules="[required('Chassis Number')]"
            ></v-text-field>
          </div>
        </div>
        <div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="model" class="c-gray-text tw-font-bold">Model</label>
            <v-autocomplete
              id="model"
              v-model="form.model"
              type="text"
              :items="vehicleModels"
              item-text="modelName"
              class="tw-mb-5"
              hide-no-data
              hide-details="auto"
              placeholder="e.g PRADO"
              :rules="[required('Model')]"
            ></v-autocomplete>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="assessment-no" class="c-gray-text tw-font-bold">Vehicle Type</label>
            <v-select
              v-model="form.bodyType"
              placeholder="e.g HATCHBACK"
              id="assessment-no"
              :rules="[required('Vehicle Type')]"
              :items="bodyTypes"
            ></v-select>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label for="mileage" class="c-gray-text tw-font-bold">Mileage</label>
            <v-text-field
              v-model="form.mileage"
              placeholder="e.g 1000"
              id="mileage"
              :rules="[required('Mileage')]"
            ></v-text-field>
          </div>
        </div>
      </div>
      <h2 class="tw-text-xl tw-mt-2 c-gray-text">Assessment Details</h2>
      <hr />
      <div
        class="
          tw-grid tw-grid-cols-1
          md:tw-grid-cols-2
          tw-gap-0
          md:tw-gap-8
          tw-py-4
          md:tw-py-6
        "
      >
        <div>
          <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-2 tw-flex">
            <span class="c-gray-text tw-font-bold tw-items-center"
              >Assessment Company</span
            >
            <span class="c-gray-text">{{
              scanObject(booking, 'externalAssessor.name')
            }}</span>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center
            "
          >
            <label for="salvage-value" class="c-gray-text tw-font-bold">PAV</label>
            <vuetify-money
              id="salvage-value"
              v-model="form.preAccidentValue"
              placeholder="Pre Accident Value"
              :rules="[required('Pre Accident Value')]"
              :options="{ precision: 0 }"
            ></vuetify-money>
          </div>
          <div
            class="
              tw-grid tw-grid-cols-2
              md:tw-grid-cols-2
              tw-flex tw-items-center tw--mt-4
            "
          >
            <label  for="SalvageValue" class="c-gray-text tw-font-bold">Salvage Value</label>
            <vuetify-money
              id="SalvageValue"
              v-model="form.salvageValue"
              placeholder="Salvage Value"
              :rules="[required('Salvage Value')]"
              :options="{ precision: 0 }"
            ></vuetify-money>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-justify-center">
        <div class="tw-w-2/3 md:tw-w-1/3 tw-py-4 md:tw-py-10">
          <v-btn
            color="primary"
            :loading="loading"
            block
            :disabled="!isValid"
            @click="processStep"
            >Next</v-btn
          >
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import validation from '@/utils/Validations'
import { formatToHuman } from '@/utils/time'
import biddingFormMixin from '@/mixins/biddingFormMixin'

export default {
  name: 'customerDetails',
  props: {
    booking: Object,
    biddingTemplate: Object,
    bodyTypes: Array,
  },
  mixins: [biddingFormMixin],
  data() {
    return {
      isValid: false,
      form: {
        claimNumber: '',
        chassisNumber: '',
        engineNumber: '',
        bodyType: '',
        model: '',
        make: '',
        yearOfManufacture: '',
        mileage: '',
        color: '',
        preAccidentValue: '',
        salvageValue: '',
      },
      loading: false,
      ...validation,
      nextSection: 'to-repair-parts',
      yearList: this.years(1980),
      yearSearch: null,
      vehicleMakes: [],
      makeId: null,
      vehicleModels: [],
    }
  },
  created: function () {
    this.getVehicleMakes()
  },
  methods: {
    years(startYear) {
      const currentYear = new Date().getFullYear()
      const years = []
      startYear = startYear || 1980
      while (startYear <= currentYear) {
        const newYear = startYear++
        years.push(newYear.toString())
      }
      return years.reverse()
    },

    updateForm: function () {
      this.form = {
        ...this.form,
        ...this.biddingTemplate,
      }
      this.form.yearOfManufacture = this.form.yearOfManufacture.toString()
    },

    getVehicleMakes: function () {
      axios
        .get('/motor-assessment/api/motor/vehicle/make')
        .then((res) => {
          this.vehicleMakes = res.data.data

          if (this.biddingTemplate?.make) {
            this.makeId = this.getMakeId()
            this.getVehicleModels()
          }
        })
        .catch((err) => {
          this.$store.commit('index/SNACKBAR', {
            state: 'failed',
            message: `${err.response.data.msg}`,
            variant: 'error',
          })
        })
    },

    handleMakeChange: function () {
      this.form.model = ''
      this.makeId = this.getMakeId()
      this.getVehicleModels()
    },

    getVehicleModels: function () {
      if (this.makeId)
        axios
          .get('/motor-assessment/api/motor/vehicle/model', {
            params: { makeId: this.makeId },
          })
          .then((res) => {
            this.vehicleModels = res.data.data
          })
          .catch((err) => {
            this.$store.commit('index/SNACKBAR', {
              state: 'failed',
              message: `${err.response.data.msg}`,
              variant: 'error',
            })
          })
    },

    getMakeId: function () {
      const selectedMake = this.form.make ?? this.biddingTemplate.make

      return selectedMake && this.vehicleMakes
        ? this.vehicleMakes.filter((make) => make.makeName === selectedMake)[0]
            .makeId
        : null
    },
  },
  filters: {
    formatToHuman,
  },
}
</script>
